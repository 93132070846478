<div class="base w100 bg-home"><div class="container"><div class="row d-flex justify-content-center align-items-center"><div class="base col-12 col-sm-6"><div class="base w100 p-5"><h1 class="title-home"><strong>Constant Connectors</strong></h1><p class="subtitle-home">The purpose of Constant Connectors is to provide a weekly opportunity to promote the growth of our members&rsquo; businesses,
offering a community of motivated individuals whose sole purpose it is to provide referrals to our network of members who can benefit from
their product or service. To accomplish this goal the group meets every Tuesday to exchange referrals, introductions &amp; leads,
as well as listen to members discuss their craft and learn more about our member&rsquo;s
specific business, ideal clients &amp; referral sources</p></div></div><div class="base col-12 col-sm-6"><log-in-form></log-in-form></div></div></div></div><div class="base w100 py-5"><div class="container"><div class="row"><div class="col-sm-12 mb-5"><p class="title-home-black text-center"><strong>Our Members</strong></p></div><div class="col-sm-12"><Carousel :settings="carouselSettings" :breakpoints="carouselBreakpoints"><Slide v-for="profile,index in profiles" :key="index"><div class="card carousel_item"><img class="card-img-top" :src="profile.company.logo || '/img/cc-logo.webp'" :alt="profile.company.name + ' logo'"><div class="card-body"><h5 class="card-title">{{profile.company.name}}</h5><h6 class="card-subtitle mb-2 text-muted">{{profile.company.industry}}</h6><a class="btn btn-primary" :href="`/profile/${profile.representatives[0]?.id}`">see profile</a></div></div></Slide></Carousel></div></div></div></div><div class="base w100 py-5 bg-white"><div class="container"><div class="row d-flex justify-content-center align-items-center"><div class="base col-12 col-sm-6"><div class="base w100 p-5"><h1 class="title-home-black"><strong>The purpose of Constant Connectors</strong></h1><p class="text-home">The purpose of Constant Connectors is to provide a weekly opportunity to promote the growth of our members&rsquo; businesses,
offering a community of motivated individuals whose sole purpose it is to provide referrals to our network of members who can benefit from
their product or service. To accomplish this goal the group meets every Tuesday to exchange referrals, introductions &amp; leads,
as well as listen to members discuss their craft and learn more about our member&rsquo;s
specific business, ideal clients &amp; referral sources</p></div></div><div class="base col-12 col-sm-6"><img class="base w100" src="/img/business-meet.webp" data-no-webp-src="/img/business-meet.jpg" alt="Constant connectors"></div></div></div></div><div class="base w100 py-5 bg-white"><div class="container"><div class="row d-flex justify-content-center align-items-center"><div class="base col-12 col-sm-6"><img class="base w100" src="/img/vision.webp" data-no-webp-src="/img/vision.jpg" alt="Constant connectors"></div><div class="base col-12 col-sm-6"><div class="base w100 p-5"><h1 class="title-home-black"><strong>The vision of Constant Connectors</strong></h1><p class="text-home">The purpose of Constant Connectors is to provide a weekly opportunity to promote the growth of our members&rsquo; businesses,
offering a community of motivated individuals whose sole purpose it is to provide referrals to our network of members who can benefit from
their product or service. To accomplish this goal the group meets every Tuesday to exchange referrals, introductions &amp; leads,
as well as listen to members discuss their craft and learn more about our member&rsquo;s
specific business, ideal clients &amp; referral sources</p></div></div></div></div></div><div class="companyRow d-sm-flex align-items-center justify-content-center"><div class="container"><div class="row"><p class="text-center">Powered by<a href="mailto:info@velansolutions.com"><img src="/img/velan-logo.webp" data-no-webp-src="/img/velan-logo.png" alt="Velan Solution" width="40" height="40"></a><a class="text-white" href="https://velansolutions.com" rel="noopener" target="_blank">V&eacute;lan Solutions LLC</a></p><p class="text-center">v {{ version }}</p></div></div></div>