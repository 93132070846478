"use strict";
exports.__esModule = true;
var vue_1 = require("vue");
var Utilities_1 = require("./Utilities");
exports["default"] = (0, vue_1.defineComponent)({
    __name: 'Dashboard',
    props: {
        created: {
            type: Object,
            required: true
        },
        received: {
            type: Object,
            required: true
        },
        otherUsers: {
            type: Object,
            required: true
        },
        createdOneOnOnes: {
            type: Object,
            required: true
        },
        invitedOneOnOnes: {
            type: Object,
            required: true
        }
    },
    setup: function (__props, _a) {
        var expose = _a.expose;
        expose();
        var props = __props;
        var __returned__ = { props: props, getTimeFormatedFromTimestamp: Utilities_1.getTimeFormatedFromTimestamp, getDateFormatedFromTimestamp: Utilities_1.getDateFormatedFromTimestamp };
        Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true });
        return __returned__;
    }
});
